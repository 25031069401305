<template>
  <div id="newmember">
    <div class="effect">
      <div class="effect_font1">预览效果：</div>
      <!-- //基础信息
      ruleForm: {
        name: '',
        describe: '',
        cover_img: '',
        is_show: '1',
        menbership: '',
        introduction: '',
      }, -->
      <div class="effect_bottom">
        <img src="../../../../assets/img/1.4.0/img_sjdbys (1).png" alt="" />
        <div class="contain">
          <!-- <img
            height="145px"
            style="width: 300px"
            src="../../../../assets/img/1.4.0/img_tbbg.png"
            alt=""
          /> -->
          <div class="tu">
            <img :src="ruleForm2.cover_img" alt="" />
            <div class="introduce">
              <div class="font1">{{ ruleForm2.name }}</div>
              <div class="font2">{{ ruleForm2.describe }}</div>
            </div>
          </div>
        </div>
        <div class="course">
          <el-tabs v-model="activeName" class="">
            <el-tab-pane label="会员课程" name="1">
              <div class="left_menbership" v-if="course && course.length > 0">
                <div
                  class="course"
                  v-for="(item3, index) in course"
                  :key="index"
                >
                  <div class="tu">
                    <img :src="item3.photo" alt="" />
                  </div>
                  <div class="right">
                    <div class="right_font1">
                      {{ item3.name }}
                    </div>
                    <div class="right_font2">
                      共{{ item3.count }}节
                      <span v-if="item3.basesales">
                        | {{ item3.basesales }}人学习
                      </span>
                    </div>
                  </div>
                  <div class="yulang_type">
                    {{
                      item3.course_type == 1
                        ? '直播'
                        : item3.course_type == 2
                        ? '小班'
                        : item3.course_type == 3
                        ? '录播'
                        : '系列'
                    }}
                  </div>
                </div>
              </div>
              <div class="introduce_font" v-else>
                <div>未添加会员课程</div>
                <div>请点击【会员课程】进行添加</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="开通会员" name="2">
              <div class="left_Opening">
                <div class="left_guige" v-if="true">
                  <div
                    class="chooseguige"
                    v-for="(item, index) in spec"
                    :key="index"
                  >
                    <div class="chooseguige_font1">{{ item.vaild_day }}天</div>
                    <div class="chooseguige_font2">￥{{ item.price }}</div>
                    <div class="chooseguige_font3" v-if="item.crossed_price">
                      ￥{{ item.crossed_price }}
                    </div>
                  </div>
                </div>
                <div class="Opening" v-else>
                  暂无数据，请点击【会员规格】进行添加
                </div>
              </div>
              <div class="left_Opening_bottom">
                <div
                  v-html="ruleForm2.introduction"
                  v-if="ruleForm2.introduction"
                ></div>
                <div class="left_Opening_bottom_font1" v-else>
                  <div>未填写会员介绍</div>
                  <div>可以在右侧【基础信息】插入图片和录入文字</div>
                </div>
              </div>
            </el-tab-pane>

            <el-tab-pane label="分享" name="3">
              <!-- <div class="dialogVisible_main" v-if="false">
                <div id="poster11">
                  <img
                    src="../../../../assets/img/1.4.0/img_fxbg (1).png"
                    alt=""
                  />
                  <div class="qrcode" id="qrcode"></div>
                  <div class="font1">
                    <div class="font_first">1</div>
                    <div class="font_scond">2</div>
                    <div class="font_third">- {{ title }} -</div>
                  </div>

                </div>
              </div> -->
              <div class="fengxiang">创建和编辑暂不支持查看分享海报</div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="left_bottom">
          <img src="../../../../assets/img/1.4.0/img_dbbq.png" alt="" />
        </div>
      </div>
    </div>
    <div class="seting" :class="{ minwidth: activeName2 === '1' }">
      <div class="seting_font1">会员设置：</div>
      <el-tabs v-model="activeName2">
        <el-tab-pane label="基础信息" name="1">
          <el-form
            label-position="left"
            label-width="100px"
            class="demo-ruleForm"
            @submit.native.prevent
          >
            <el-form-item label="会员名称" :required="true">
              <el-input
                placeholder="请输入会员名称"
                v-model="ruleForm.name"
                maxlength="12"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="会员描述" :required="true">
              <el-input
                placeholder="请输入会员描述"
                v-model="ruleForm.describe"
                maxlength="18"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="展示风格" :required="true">
              <div class="image_contain">
                <div
                  @click="isclick_stustus(index)"
                  class="showstyle"
                  v-for="(i, index) in photolist"
                  :key="index"
                >
                  <img :src="i" alt="" />

                  <div class="isclick" v-if="isclick == index">
                    <img
                      src="../../../../assets/img/1.4.0/icon_dg.png"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  class="custom"
                  @click="customImage"
                  v-if="photolist.length < 5"
                >
                  自定义
                </div>
                <div class="edit" v-if="isclick == 4" @click="editphoto">
                  编辑
                </div>
              </div>
            </el-form-item>
            <el-form-item label="网校展示" :required="true">
              <el-radio-group v-model="ruleForm.is_show">
                <el-radio label="1">开启</el-radio>
                <el-radio label="2">关闭</el-radio>
              </el-radio-group>
              <div class="schoolstyle">
                开启后会员信息会在网校展示，学员可进行购买；隐藏后不会在网校显示会员信息，只可单独设置学员为会员角色
              </div>
            </el-form-item>
            <el-form-item label="会员介绍" prop="menbership">
              <editor class="editor1" v-model="ruleForm.introduction"></editor>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="`会员规格(${count})`" name="2">
          <div class="Specifications">
            <div class="left">
              <div class="redcolor">*</div>
              <div class="font1">会员规格</div>
            </div>
            <div class="right">
              <!-- {{ spec }} -->
              <div class="format" v-for="(i, index) in count" :key="index">
                <el-form ref="form" label-position="left" label-width="80px">
                  <el-form-item label="有效期">
                    <el-radio-group
                      v-model="spec[index].is_system"
                      @change="is_system_radio(index)"
                    >
                      <el-radio label="1">系统</el-radio>
                      <el-radio label="2">自定义</el-radio>
                    </el-radio-group>
                    <el-select
                      v-if="spec[index].is_system == 1"
                      style="width: 260px"
                      size="medium"
                      v-model="spec[index].vaild_day"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                    <el-input
                      placeholder="请输入会员有效期"
                      v-else
                      size="medium"
                      v-model="spec[index].vaild_day"
                      @input="vaild_day(index)"
                      style="width: 260px"
                    ></el-input>
                    <span class="danwei">天</span>
                  </el-form-item>
                  <el-form-item label="价格">
                    <el-input
                      placeholder="请输入价格"
                      size="medium"
                      :value="spec[index].price"
                      @input="spec[index].price = toNumber(arguments[0])"
                      style="width: 260px"
                    ></el-input>
                    <span class="danwei">元</span>
                  </el-form-item>

                  <el-form-item label="划线价">
                    <el-input
                      placeholder="请输入金额，选填"
                      :value="spec[index].crossed_price"
                      @input="
                        spec[index].crossed_price = toNumber(arguments[0])
                      "
                      size="medium"
                      style="width: 260px"
                    ></el-input>
                    <span class="danwei">元</span>
                  </el-form-item>

                  <el-form-item label="每人限购">
                    <el-radio-group v-model="spec[index].buy_number">
                      <el-radio label="1">无限</el-radio>
                      <el-radio label="2">限定次数</el-radio>
                    </el-radio-group>
                    <div v-if="spec[index].buy_number == 2">
                      <el-input
                        placeholder="请输入限定次数"
                        @input="buy_limit_num(index)"
                        size="medium"
                        v-model="spec[index].buy_limit_num"
                        style="width: 260px"
                      ></el-input>
                      <span class="danwei">次</span>
                    </div>
                  </el-form-item>
                </el-form>
                <div class="close">
                  <el-popconfirm
                    @confirm="del(index)"
                    icon-color="red"
                    icon="el-icon-info"
                    title="是否要删除该规格？"
                  >
                    <i slot="reference" class="el-icon-close close"></i>
                  </el-popconfirm>
                </div>
              </div>
            </div>
          </div>
          <div class="btns2 fontcolor_green">
            <el-button
              type=""
              style="width: 126px"
              size="medium"
              @click="addcount"
            >
              添加规格
            </el-button>
            <span class="btns2_font">还可添加{{ 4 - count }}个规格</span>
          </div>
        </el-tab-pane>

        <el-tab-pane
          :label="`会员课程(${course ? course.length : 0})`"
          name="3"
        >
          <div class="membershipCourse">
            <div class="header fontcolor_green">
              <el-button type="" style="width: 126px" @click="chooseCourse">
                +选择课程
              </el-button>
              <div class="font1">
                添加课程后，学员成为会员即可拥有学习权限，无需购买课程
              </div>
            </div>
            <div class="main">
              <div class="main_course" v-if="course && course.length > 0">
                <div
                  class="course"
                  v-for="(item2, index) in course"
                  :key="index"
                >
                  <div class="tu_name">
                    <div class="tu">
                      <img
                        style="width: 40px; heigth: 22px"
                        :src="item2.photo"
                        alt=""
                      />
                    </div>
                    <div class="name">{{ item2.name }}</div>
                  </div>
                  <div class="type_price">
                    <div class="type">
                      {{
                        item2.course_type == 1
                          ? '直播课'
                          : item2.course_type == 2
                          ? '小班课'
                          : item2.course_type == 3
                          ? '录播课'
                          : '系列课'
                      }}
                    </div>
                    <div class="price" v-if="item2.price > 0">
                      ￥{{ item2.price }}
                    </div>
                    <div class="price" v-else>免费</div>
                    <el-popconfirm
                      icon="el-icon-info"
                      icon-color="red"
                      title="是否要移除该课程？"
                      @confirm="moveSaveClass(item2.course_id)"
                    >
                      <el-button slot="reference" type="text" size="mini">
                        移除
                      </el-button>
                    </el-popconfirm>
                  </div>
                </div>
              </div>
              <div class="empty" v-else>
                未添加课程，请点击【选择课程】进行添加
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="btns">
        <div class="fontcolor_green">
          <el-button
            type=""
            class="fontcolor_green"
            @click="next"
            v-if="Number(activeName2) < 3"
          >
            下一步
          </el-button>
          <el-button type="" @click="next" v-else>上一步</el-button>
        </div>
        <el-button type="primary" @click="preservation">保存</el-button>
      </div>
    </div>
    <changePhotoimg
      :isshow="true"
      ref="changePhotoimg"
      @complete="complete"
      class="changePhotoimg"
      :size="5"
    ></changePhotoimg>
    <!-- 图片选择 -->
    <el-dialog
      class="dialogVisible"
      title="自定义展示风格"
      :visible.sync="dialogVisible"
      width="560px"
    >
      <div class="dialogVisible_main" @click="upload">
        <img style="width: 100%" v-if="img_url" :src="img_url" alt="" />
        <div v-else style="margin: 0 auto">点击上传图片</div>
      </div>

      <div class="delphoto" @click="delphoto" v-if="img_url">删除图片</div>
      <span slot="footer" class="dialog-footer">
        <div class="dialogVisible_main_font1 fl">
          建议图片为深色背景图，不带文字，以免影响标题显示哦；尺寸800*450px，JPG、PNG格式，
          图片小于5M
        </div>
        <el-button @click="dialogVisible_cancel">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_sure">保存</el-button>
      </span>
    </el-dialog>

    <!-- 课程选择 -->
    <el-dialog
      title="选择课程"
      width="850px"
      :visible.sync="dialogSelectVisible"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <div slot="title">
        选择课程
        <span style="color: #ff3535; font-size: 13px">(多选)</span>
      </div>
      <dialog-selectcourse ref="dialogRef" :type="2"></dialog-selectcourse>
    </el-dialog>
  </div>
</template>
<script>
import changePhotoimg from '@cm/base/changePhotoimg'
import dialogSelectcourse from '@/components/base/dialogSelectcourse'
import editor from '@cm/editor'
export default {
  name: 'newmember',
  data() {
    return {
      retain: '', //删除图片后,点击取消按钮保留上一次的图片，再次打开弹窗是显示当前的图像
      existence: '', //判断是否有自定义的图
      dialogSelectVisible: false, //控制选择课程
      isclick: 0,
      img_url: '', //上传的图片
      photolist: [], //展示风格的列表
      //自定义图像的弹窗
      dialogVisible: false,
      //会员规格个数控制
      count: 1,
      options: [
        {
          value: 1,
          label: '1',
        },
        {
          value: 7,
          label: '7',
        },
        {
          value: 15,
          label: '15',
        },
        {
          value: 30,
          label: '30',
        },
        {
          value: 60,
          label: '60',
        },
        {
          value: 120,
          label: '120',
        },
        {
          value: 365,
          label: '365',
        },
      ],
      token: '',
      // 编辑器课程详情
      introduction: '',
      course_ids: '', //选择的课程id
      course: [], //选择的课程
      //会员规格
      spec: [
        {
          is_system: '1',
          vaild_day: 7,
          price: '',
          crossed_price: '',
          buy_limit_num: '1',
          buy_number: '1',
        },
      ],
      //基础信息
      ruleForm: {
        name: '',
        describe: '开通VIP，畅学多门高级课程',
        cover_img: '',
        is_show: '1',
        introduction: '',
      },
      //左边的
      activeName: '1',
      //右边的
      activeName2: '1',
    }
  },
  created() {
    if (this.$route.query.iscount && this.$route.query.iscount == 2) {
      this.activeName2 = '3'
    }
  },
  computed: {
    ruleForm2() {
      let array = []
      //判断是否位空,如果是空的,就给cover_img赋一个初始值
      //这样写是为了防改变张氏风格下边的一些字段是,数值会有恢复成一个张图
      if (!this.ruleForm.cover_img) {
        array = _.assign({}, this.ruleForm, {
          cover_img: this.photolist[0],
        })
      } else {
        array = _.assign({}, this.ruleForm)
      }
      return array
    },
    member_id() {
      return this.$route.query.member_id || ''
    },

    title() {
      return this.$store.state.userInfo.jg_title
    },
  },
  mounted() {
    if (this.$route.query.type) {
      this.getdata()
      this.$root.$children[0].childPageOptions[
        this.$root.$children[0].childPageOptions.length - 1
      ].name = '编辑'
    }
    this.coverImg()
  },
  components: {
    editor,
    dialogSelectcourse,
    changePhotoimg,
  },

  methods: {
    complete(val) {
      this.img_url = val
    },
    upload() {
      this.$refs.changePhotoimg.changePicture()
    },
    // 验证金额
    toNumber(value) {
      let newValue = value.match(/^[0-9]+(?:\.?\d{0,2})?/g)
      newValue = newValue ? newValue[0] : ''
      let formattedValue = newValue
      if (newValue) {
        if (newValue.indexOf('.') > 7) {
          formattedValue =
            newValue.split('.')[0].slice(0, 7) +
            '.' +
            newValue.split('.')[1].slice(0, 2)
        } else {
          formattedValue = newValue.slice(
            0,
            newValue.indexOf('.') === -1 ? 7 : newValue.indexOf('.') + 3
          )
        }
      }
      return formattedValue
    },
    //有效期切换事件
    is_system_radio(index) {
      if (this.spec[index].is_system == 1) {
        this.spec[index].vaild_day = 7
      }
    },

    //限购次数
    buy_limit_num(index) {
      if (this.spec[index].buy_limit_num <= 0) {
        this.spec[index].buy_limit_num = ''
      }
      this.spec[index].buy_limit_num = this.spec[index].buy_limit_num.replace(
        /[^\d]/g,
        ''
      )

      this.spec[index].buy_limit_num = this.spec[index].buy_limit_num.slice(
        0,
        5
      )
    },

    //有效期输入框的限制
    vaild_day(index) {
      if (this.spec[index].vaild_day <= 0) {
        this.spec[index].vaild_day = ''
      }
      this.spec[index].vaild_day = this.spec[index].vaild_day.replace(
        /[^\d]/g,
        ''
      )

      this.spec[index].vaild_day = this.spec[index].vaild_day.slice(0, 5)
    },
    getdata() {
      this.$http({
        url: '/courseMember/moreCourseMember',
        data: {
          member_id: this.$route.query.member_id,
        },
        callback: ({ data }) => {
          this.ruleForm = data.basics
          this.course = data.course || []
          this.spec = data.spec
          this.count = data.spec.length
          setTimeout(() => {
            this.existence = this.photolist.filter(
              f => f === data.basics.cover_img
            ).length
            if (this.existence == 0) {
              this.photolist.push(data.basics.cover_img)
              this.isclick_stustus(4)
            } else {
              let index = this.photolist.findIndex(
                f => f === data.basics.cover_img
              )
              this.isclick_stustus(index)
            }
          }, 100)
        },
      })
    },
    //保存内容
    preservation() {
      if (!this.ruleForm2.name || !this.ruleForm2.describe) {
        this.$root.prompt({
          msg: '会员设置未完成，基础信息和会员规格均为必填项！',
        })
        return
      }
      this.course_ids = this.course.map(f => f.course_id).join(',')
      let post = _.assign({}, this.ruleForm2, {
        spec: this.spec,
        course_ids: this.course_ids,
      })
      if (this.$route.query.type) {
        this.$http({
          url: '/courseMember/setCourseMember',
          data: post,
          callback: () => {
            this.$router.replace({
              path: '/tool/member/memberexamine',
              query: { member_id: this.$route.query.member_id },
            })
          },
          error: ({ info }) => {
            this.$root.prompt(info)
          },
        })
      } else {
        this.$http({
          url: '/courseMember/addCourseMember',
          data: post,
          callback: ({ member_id }) => {
            this.$router.replace({
              path: '/tool/member/memberexamine',
              query: { member_id: member_id },
            })
          },
          error: ({ info }) => {
            this.$root.prompt(info)
          },
        })
      }
    },

    // //点击移除
    moveSaveClass(id) {
      this.course.forEach((item, index) => {
        if (item.course_id == id) {
          this.course.splice(index, 1)
        }
      })
      // this.course_ids = this.course.map((f) => f.course_id).join(',')
    },
    //选择课程
    chooseCourse() {
      //点击选择课程遍历拿到课程id传给后台
      this.dialogSelectVisible = true
      //重新拉取树形菜单
      let _this = this
      this.$nextTick(() => {
        _this.$refs.dialogRef.getMenu(_this.course, _this.member_id)
        _this.$refs.dialogRef.clearSelect()
      })
    },

    cancelDialog() {
      this.dialogSelectVisible = false
    },

    gbDialog(list) {
      //搭配组件使用的方法,关闭弹出框
      this.dialogSelectVisible = false
      for (let value of list.values()) {
        this.course.push(value)
      }
    },
    //展示风格切换
    isclick_stustus(val) {
      this.isclick = val
      this.ruleForm2.cover_img = this.photolist[val]
      this.ruleForm.cover_img = this.photolist[val]
    },
    //点击确定
    dialogVisible_sure() {
      if (!this.img_url) {
        this.$root.prompt({
          msg: '请先选择图片',
        })
        return
      }
      this.dialogVisible = false

      this.photolist[4] = this.img_url
      this.isclick_stustus(4)
    },
    dialogVisible_cancel() {
      this.img_url = this.retain
      this.dialogVisible = false
    },
    //删除图片
    delphoto() {
      this.retain = this.img_url
      this.img_url = ''
    },
    // 获取修改后的封面图
    getResultImg(img_url) {
      this.img_url = img_url
    }, // 上传图片过大
    errorHappen(flag) {
      this.isShowWarning = flag
    },
    //获取展示风格数据
    coverImg() {
      this.$http({
        url: '/courseMember/coverImg',
        data: {},
        callback: ({ data }) => {
          this.photolist = data
        },
      })
    },
    //自定义选择图像
    customImage() {
      this.img_url = ''
      this.dialogVisible = true
    },
    editphoto() {
      this.dialogVisible = true

      if (this.$route.query.type && this.existence == 0) {
        this.img_url = this.ruleForm.cover_img
      }
    },
    //下一步的点击事件
    next() {
      if (Number(this.activeName2) < 3) {
        this.activeName2 = String(Number(this.activeName2) + 1)
      } else {
        this.activeName2 = String(Number(this.activeName2) - 1)
      }
    },
    //删除规格
    del(val) {
      if (this.spec.length == 1) {
        this.$root.prompt({
          msg: '会员规格必须保留一项！',
        })
        return
      }
      this.spec.splice(val, 1)
      this.count -= 1
    },
    //添加规格
    addcount() {
      if (this.count == 4) {
        this.$root.prompt({
          msg: '会员规格最多可添加4个',
        })
        return
      }
      this.count += 1
      this.spec.push({
        is_system: '1',
        vaild_day: 7,
        price: '',
        crossed_price: '',
        buy_limit_num: '1',
        buy_number: '1',
      })
    },
    printLog(title, info) {
      window.console && console.log(title, info)
    },
  },
}
</script>
<style lang="scss" scoped>
.changePhotoimg {
  height: 0 !important;
  width: 0 !important;
  position: absolute;
  background-color: red;
}
::v-deep .pcWxUploadBtn {
  left: 159px;
  width: 62%;
}
::v-deep .showUploadPs {
  width: 260px;
  left: 160px;
}
.fontcolor_green {
  ::v-deep .el-button {
    border-color: #1b9d97;
    color: #1b9d97;
  }
}
.fengxiang {
  font-size: 12px;
  color: #666666;
  line-height: 18px;
  text-align: center;
  margin: 115px 0 138px;
}
.dialogVisible_main {
  width: 280px;
  margin: 0 auto;
  position: relative;

  .qrcode {
    box-sizing: border-box;
    overflow: hidden;
    padding: 5px;
    height: 118px;
    width: 118px;
    background-color: #fff;
    position: absolute;
    top: 49px;
    left: 50%;
    transform: translateX(-50%);
  }
  .font1 {
    height: 92px;
    width: 100%;
    position: absolute;
    top: 187px;
    .font_first {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      line-height: 18px;
    }
    .font_scond {
      text-align: center;
      font-size: 12px;
      color: #ffffff;
      line-height: 12px;
      margin: 12px 0 25px;
    }
    .font_third {
      text-align: center;
      font-size: 12px;
      color: #ffffff;
      line-height: 12px;
    }
  }
  .btns {
    display: flex;
    justify-content: center;

    a {
      text-decoration: none;
      color: #0aa29b;
    }
  }

  .result-pic {
    width: 280px;
    height: 314px;
    position: absolute;
    top: 0px;
    left: 0px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
::v-deep .el-tabs__nav-wrap:after {
  height: 0;
}
.dialogVisible {
  .el-dialog__body {
    height: 167px;
  }
  .dialogVisible_main {
    display: flex;
    align-items: center;
    margin-left: 158px;
    width: 260px;
    height: 146px;
    background: #ffffff;
    border: 1px dashed #b2b2b2;
    text-align: center;
    font-size: 14px;
    color: #666666;
    line-height: 14px;
    overflow: hidden;
    box-sizing: border-box;
    cursor: pointer;
  }
  .delphoto {
    cursor: pointer;
    font-size: 14px;
    color: #ff3535;
    line-height: 14px;
    margin-top: 20px;
    margin-left: 261px;
  }
  .dialogVisible_main_font1 {
    width: 344px;
    height: 32px;
    font-size: 12px;
    color: #666666;
    line-height: 16px;
    margin-right: 14px;
    text-align: left;
  }
}

::v-deep .el-popconfirm__action {
  margin-top: 10px !important;
}
::v-deep .el-select .el-input .el-select__caret {
  height: 36px;
}
.danwei {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-left: 10px;
}
::v-deep .el-form-item {
  margin-bottom: 30px;
}
.w-e-text-container {
  height: 500px !important;
}
#newmember {
  background-color: #f5f5f5;
  display: flex;
  .effect {
    width: 320px;
    padding: 20px;
    padding-bottom: 36px;
    background-color: #fff;
    .effect_font1 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 16px;
      margin-bottom: 30px;
    }
    .effect_bottom {
      background: linear-gradient(180deg, #494949 0%, #161616 100%);
      height: calc(100% - 73px);
      width: 300px;
      border: 1px solid #eeeeee;
      img {
        width: 100%;
      }
      .contain {
        margin-bottom: 16px;
        position: relative;
        .tu {
          overflow: hidden;
          display: flex;
          align-items: center;
          margin: 20px auto 0;
          width: 240px;
          height: 135px;
          border-radius: 6px;
          position: relative;
          .introduce {
            position: absolute;
            top: 61px;
            left: 12px;
            height: 36px;
            .font1 {
              font-size: 14px;
              font-weight: bold;
              color: #ffffff;
              line-height: 14px;
            }
            .font2 {
              margin-top: 10px;
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
              line-height: 12px;
            }
          }
          img {
            width: 100%;
          }
        }
      }
      .course {
        border-radius: 16px 16px 0px 0px;
        height: calc(100% - 240px);
        background-color: #fff;
        .introduce_font {
          margin: 154px auto;
          width: 254px;
          font-size: 12px;
          color: #666666;
          line-height: 18px;
          text-align: center;
          margin-top: 154px;
        }
        .mumber_course {
          .header {
            display: flex;
            align-items: center;
            justify-content: center;
            .font1 {
              margin: 0 10px;
              font-size: 13px;
              color: #1b9d97;
              line-height: 13px;
            }
            img {
              width: 78px;
              height: 1px;
            }
          }
        }
        .font2 {
          text-align: center;
          margin: 40px 0 37px;
          font-size: 12px;
          color: #666666;
          line-height: 16px;
        }

        ::v-deep .el-tabs__nav-wrap {
          padding-left: 19px;
        }
        .left_menbership {
          margin-top: 33px;
          margin-left: 10px;
          .course {
            display: flex;
            margin-bottom: 32px;
            position: relative;
            .tu {
              width: 99px;
              height: 56px;
              margin-right: 10px;
            }
            .right {
              .right_font1 {
                width: 170px;
                height: 30px;
                font-size: 12px;
                font-weight: 600;
                color: #333333;
                line-height: 15px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .right_font2 {
                margin-top: 16px;
                font-size: 12px;
                font-weight: 400;
                color: #999999;
                line-height: 12px;
              }
            }

            .yulang_type {
              position: absolute;
              left: 71px;
              bottom: 2px;
              width: 28px;
              height: 16px;
              background: #000000;
              opacity: 0.7;
              color: #fff;
              padding: 2px 0 0 2px;
              box-sizing: border-box;
              font-size: 12px;
              font-weight: 500;
              color: #ffffff;
              line-height: 12px;
            }
          }
        }
        .left_Opening {
          .Opening {
            margin-bottom: 112px;
            margin-top: 40px;
            text-align: center;
            font-size: 12px;
            color: #666666;
            line-height: 16px;
          }
          .left_guige {
            display: flex;
            flex-wrap: wrap;
            margin-top: 30px;
            .chooseguige {
              margin-bottom: 30px;
              width: 135px;
              height: 89px;
              background: #ffffff;
              border-radius: 6px;
              border: 1px solid #e3e3e3;
              margin-left: 10px;
              text-align: center;
              .chooseguige_font1 {
                margin-top: 14px;
                font-size: 16px;
                font-weight: bold;
                color: #333333;
                line-height: 16px;
              }
              .chooseguige_font2 {
                margin-top: 10px;
                font-size: 18px;
                font-weight: bold;
                color: #ff3535;
                line-height: 18px;
              }
              .chooseguige_font3 {
                margin-top: 5px;
                font-size: 12px;
                color: #999999;
                line-height: 12px;
                text-decoration: line-through;
              }
            }
          }
        }

        .left_Opening_bottom {
          padding: 0 10px;
          .left_Opening_bottom_font1 {
            margin: 70px 0;
            text-align: center;
            font-size: 12px;
            color: #666666;
            line-height: 18px;
          }
        }
      }
      .left_bottom {
        box-sizing: border-box;
        img {
          border-left: 1px solid #eeeeee;
          border-bottom: 1px solid #eeeeee;
          border-right: 1px solid #eeeeee;
          width: 300px;
          transform: translateX(-1px);
        }
      }
    }
  }
  .minwidth {
    min-width: 683px;
  }
  .seting {
    position: relative;
    width: calc(100% - 343px);
    background-color: #fff;
    margin-left: 20px;
    ::v-deep .el-tabs__content {
      padding: 10px 20px 20px;
    }
    .seting_font1 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 16px;
      margin: 20px 0px 30px 20px;
    }
    .image_contain {
      position: relative;
      display: flex;
      .showstyle,
      .custom {
        position: relative;
        width: 68px;
        height: 36px;
        border-radius: 2px;
        margin-right: 20px;
        cursor: pointer;
        .isclick {
          position: absolute;
          top: 0;
          left: 13px;
          img {
            height: 40px;
            width: 40px;
          }
        }
      }

      .edit {
        cursor: pointer;
        position: absolute;
        left: 373px;
        bottom: -19px;
        font-size: 12px;
        color: #1b9d97;
        line-height: 12px;
        text-align: center;
        margin-top: 8px;
      }
      .showstyle {
        overflow: hidden;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          border-radius: 2px;
        }
      }
      .custom {
        background-color: #e6e6e6;
        font-size: 13px;
        color: #999999;
        text-align: center;

        img {
          width: 68px;
          height: 36px;
        }
      }
    }

    .schoolstyle {
      font-size: 12px;
      color: #666666;
      line-height: 14px;
    }

    .editor1 {
      margin-bottom: 40px;
    }

    .btns {
      margin-left: 20px;
      margin-bottom: 20px;
      bottom: 0;
      position: absolute;
      display: flex;
      .el-button {
        width: 126px;
        margin-right: 20px;
      }
    }
    .Specifications {
      display: flex;
      .left {
        width: 112px;
        display: flex;
        .font1 {
          margin-left: 3px;
          font-size: 14px;
          color: #333333;
          line-height: 19px;
        }
      }
      .right {
        width: calc(100% - 112px);
        .format {
          position: relative;
          margin-bottom: 20px;
          width: 404px;
          height: 336px;
          background: #f5f5f5;
          padding: 20px;
          overflow: hidden;
          box-sizing: border-box;
          ::v-deep .el-form-item {
            margin-bottom: 17px;
          }
          .close {
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
          }
        }
      }
    }
    .membershipCourse {
      .header {
        display: flex;
        align-items: center;
        .font1 {
          font-size: 12px;
          color: #666666;
          line-height: 16px;
          margin-left: 20px;
        }
      }
      .main {
        .empty {
          font-size: 12px;
          color: #666666;
          text-align: center;
          line-height: 16px;
          margin-top: 169px;
        }
        .main_course {
          margin-top: 30px;
          margin-bottom: 60px;
          .course {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
            .tu_name {
              display: flex;
              align-items: center;
              width: calc(100% - 215px);
              .tu {
                margin-right: 10px;
                background-color: red;
                width: 40px;
                height: 22px;
              }
              .name {
                font-size: 13px;
                color: #333333;
                line-height: 13px;
                width: calc(100% - 60px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
            .type_price {
              align-items: center;
              display: flex;
              .type {
                min-width: 40px;
                margin: 0 20px;
                font-size: 13px;
                color: #333333;
                line-height: 13px;
              }
              .price {
                margin-right: 10px;
                min-width: 100px;
                font-size: 13px;
                color: #ff3535;
                line-height: 13px;
              }
            }
          }
        }
      }
    }

    .btns2 {
      margin-left: 113px;
      margin-bottom: 95px;
      .btns2_font {
        font-size: 13px;
        color: #999999;
        margin-left: 20px;
        line-height: 13px;
      }
    }
  }
}
</style>
